<template>
	<WDialog
		v-model="displayed"
		max-width="50vw"
		:title="$t('modules_custom_parameters.dialog.title')"
		@keydown.esc="closeDialog"
		@keydown.enter="submit()"
		@close="closeDialog"
	>
		<v-layout column>
			<v-flex v-for="(input, index) in inputsArray" :key="index">
				<v-text-field
					v-if="['text', 'url'].includes(input.type)"
					v-model="formatterData[input.name]"
					:label="`${$t('modules_custom_parameters.label.' + input.name)}${input.required ? '*' : ''}`"
					:rules="input.rules"
				/>
			</v-flex>
		</v-layout>

		<template v-slot:actions>
			<v-layout justify-end row>
				<WButton :disabled="!canSave" flat @click="submit()">{{ $t('actions.save') }}</WButton>
				<WButton flat @click="closeDialog()">{{ $t('actions.cancel') }}</WButton>
			</v-layout>
		</template>
	</WDialog>
</template>

<script>
import Validator from '@/mixins/Validator'

export default {
	name: 'ModulesParametersConfigDialog',
	mixins: [Validator],
	props: {
		inputs: {
			required: true,
			type: Array
		},
		defaultValues: {
			required: false,
			type: [Object, String],
			default: function () {
				return {}
			}
		}
	},
	data: function () {
		return {
			displayed: false,
			formatterData: {},
			parametersFormatters: []
		}
	},
	computed: {
		canSave: function () {
			let result = true

			for (let i = 0; i < this.inputsArray.length; i++) {
				const inputSettings = this.inputsArray[i]
				const value = this.formatterData[inputSettings.name]
				if (inputSettings.required && !value) {
					result = false
				}

				if (inputSettings.rules) {
					for (let j = 0; j < inputSettings.rules.length; j++) {
						const rule = inputSettings.rules[j]
						if (typeof rule(value) === 'string') {
							result = false
						}
					}
				}

				if (!result) {
					break
				}
			}

			return result
		},
		inputsArray: function () {
			const rules = this.rules
			return this.inputs.map(input => {
				input.rules = []
				if (input.required) {
					input.rules.push(rules.required)
				}
				if (input.type === 'url') {
					input.rules.push(rules.url)
				}
				return input
			})
		}
	},
	watch: {
		inputsArray: {
			handler: function (val) {
				this.resetFormatterDataAndSetDefaultValues(val)
			},
			immediate: true
		},
		defaultValues: {
			handler: function (val) {
				this.resetFormatterDataAndSetDefaultValues(val)
			},
			immediate: true
		}
	},
	methods: {
		closeDialog: function () {
			this.$emit('closeParametersConfigDialog')
		},
		hide: function () {
			this.displayed = false
		},
		resetFormatterDataAndSetDefaultValues: function (val) {
			this.resetFormatterData()
			if (val) {
				this.setDefaultValues()
			}
		},
		resetFormatterData: function () {
			this.formatterData = {}
		},
		setDefaultValues: function () {
			if (!this.defaultValues) {
				return
			}

			let defaultValues = this.defaultValues
			if (typeof defaultValues === 'string') {
				defaultValues = JSON.parse(defaultValues)
			}

			Object.entries(defaultValues).forEach(item => {
				const key = item[0]
				const value = item[1]
				let resultEntry = this.inputs.find(input => input.name === key)
				if (resultEntry) {
					this.setFormatterData(key, value)
				}
			})
		},
		setFormatterData: function (name, value) {
			this.formatterData[name] = value
		},
		show: function () {
			this.displayed = true
		},
		submit: function () {
			this.$emit('saveParametersConfigDialog', this.formatterData)
		}
	}
}
</script>
